// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-betriebe-js": () => import("./../../../src/pages/betriebe.js" /* webpackChunkName: "component---src-pages-betriebe-js" */),
  "component---src-pages-datenschutz-js": () => import("./../../../src/pages/datenschutz.js" /* webpackChunkName: "component---src-pages-datenschutz-js" */),
  "component---src-pages-impressum-js": () => import("./../../../src/pages/impressum.js" /* webpackChunkName: "component---src-pages-impressum-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-vision-js": () => import("./../../../src/pages/vision.js" /* webpackChunkName: "component---src-pages-vision-js" */),
  "component---src-pages-vorstand-js": () => import("./../../../src/pages/vorstand.js" /* webpackChunkName: "component---src-pages-vorstand-js" */),
  "component---src-templates-event-list-template-js": () => import("./../../../src/templates/event-list-template.js" /* webpackChunkName: "component---src-templates-event-list-template-js" */),
  "component---src-templates-event-template-js": () => import("./../../../src/templates/event-template.js" /* webpackChunkName: "component---src-templates-event-template-js" */)
}

